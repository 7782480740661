import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Img,
  Stack,
  SimpleGrid,
  Text,
  useTheme
} from "@chakra-ui/react";

import Template from "src/templates/index";
import { Logo } from "atoms/Logo";
import { SetTitle } from "atoms/SetTitle";
import SEO from "../components/seo";

import InteractiveStoryVideo from "src/videos/InteractiveStoryQuickDemo.mp4";
import TextAdventureVideo from "src/videos/TextAdventureQuickDemo.mp4";
import Features from "pages/index/features";
import { VideoLoop } from "components/atoms/VideoLoop";
import { PopularStories } from "components/organisms/popular-stories";

const ResponsiveHeading = (props: any) => (
  <Heading as="h1" fontSize={["lg", "4xl", "5xl"]} {...props} />
);

const largerBreak = [20, 36];
const smallerBreak = [4, 10, 18];

const ChoiceHeading = (props: any) => (
  <Heading as="h2" fontSize={["lg", "2xl", "4xl"]} {...props} />
);

const IndexPage = () => {
  const primaryColor = useTheme().colors.primary;
  return (
    <Template>
      <SetTitle title="Wrdie" />
      <Box bg="white" color="primary" w="100vw" pb="10">
        <Box as="section">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Heading as="h1" mr="4" fontSize={["lg", "4xl", "5xl"]}>
              With
            </Heading>
            <Flex
              alignItems="baseline"
              align="center"
              mb={largerBreak}
              direction="row"
            >
              <Box mb="10px">
                <Logo size={["10em", "20em", "md"]} color="#0E79B2" />
              </Box>
              <Heading as="h1" fontSize={["lg", "4xl", "5xl"]} ml="4">
                you can...
              </Heading>
            </Flex>

            <Box textAlign="center" mb={largerBreak}>
              <ResponsiveHeading mb="3">
                write interactive stories...
              </ResponsiveHeading>

              <Box mb={largerBreak}>
                <VideoLoop width="960px" source={InteractiveStoryVideo} />
              </Box>

              <ResponsiveHeading mb="3">
                and build interactive worlds.
              </ResponsiveHeading>

              <VideoLoop
                width="960px"
                source={TextAdventureVideo}
                mb={largerBreak}
              />

              <ResponsiveHeading mb={4}>
                Wrdie is a new platform for interactive self-expression.
              </ResponsiveHeading>

              <Features />

              <ResponsiveHeading mb="3">Get started now...</ResponsiveHeading>

              <Box mb={smallerBreak}>
                <ChoiceHeading>
                  Pick a story below or{" "}
                  <Box as="span" color="purple.500">
                    <a href="/home?new=true">start writing your own</a>
                  </Box>{" "}
                </ChoiceHeading>
              </Box>
              <PopularStories />
            </Box>
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default IndexPage;
