import * as React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  faPlay,
  faBookOpen,
  faPen,
  faComments
} from "@fortawesome/pro-regular-svg-icons";

import FeatureTab from "./feature-tab";

const Features: React.FC = () => {
  return (
    <Box as="section" pb="32">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY="10">
          <FeatureTab title="Write interactive stories" icon={faBookOpen}>
            Wrdie makes it easy to write branching, choose-your-own-adventure
            style stories. Stories are written as pages that link to other
            pages.
          </FeatureTab>
          <FeatureTab title="Play stories written by others" icon={faPlay}>
            All stories are hosted on Wrdie. Pick a story and start playing
            right away. All of your characters save to your account so you'll
            never lose your progress.
          </FeatureTab>
          <FeatureTab title="Build interactive worlds" icon={faPen}>
            Wrdie can also create browser-based text adventures. Create
            locations and connect them with exits, then use the powerful drag
            and drop tools to modify the text dynamically.
          </FeatureTab>
          <FeatureTab title="Join the community" icon={faComments}>
            Wrdie is always updating with new features based on community
            feedback. We offer helpful videos and tutorials and a full set of
            reference documentation.
          </FeatureTab>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Features;
