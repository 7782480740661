import React, { useRef } from "react";
import { Box, Img, BoxProps } from "@chakra-ui/react";

import BrowserMockup from "src/images/BrowserMockup.png";
import { useContainerDimensions } from "src/hooks/useContainerDimensions";

interface OwnProps extends BoxProps {
  source: any;
  height: string;
  width: string;
  showBrowser: boolean;
}

export const VideoLoop: React.FC<OwnProps> = ({
  source,
  height,
  width,
  showBrowser = true,
  ...rest
}) => {
  const containerRef = useRef(null);
  const {
    width: containerWidth,
    height: containerHeight
  } = useContainerDimensions(containerRef);

  return (
    <Box mb={4} {...rest} ref={containerRef}>
      <Img src={BrowserMockup} w={width} mx="auto" />
      <video
        autoPlay
        loop
        muted
        style={{ height, width, margin: "0 auto" }}
        height={height}
        width={width}
      >
        <source src={source} type="video/mp4" />
      </video>
    </Box>
  );
};
